import React from "react"

import Header from "../components/header/header"
import Navigation from "../components/navigation/navigation"
import SocialFooter from "../components/social-footer/social-footer"
import Footer from "../components/footer/footer"
import PageHeader from "../components/page-header/page-header"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

export default function Home() {
  var styles = {
    lise: {
      backgroundImage: 'url("/images/photo_lise_bourbeau.png")',
    },
    david: {
      backgroundImage: 'url("/images/photo_david_laroche.jpg")',
    },
    cerise: {
      backgroundImage: 'url("/images/photo_cerise_bonneaud.jpg")',
    },
  }

  return (
    <>
      <SEO title={"Coaching"} keywords={[`coaching`]} />
      <div className="at-sitecontainer at-sitecontainer--wide">
        <Header />
        <Navigation />
        <PageHeader title="Coaching" header="/images/headers/4.jpg" />
        <Layout>
          <article>
            <div className="at-page__content">
              <p>
                <img
                  src={"/images/coaching.jpg"}
                  className="size-medium alignleft"
                  alt="Diane Le Blanc - Séance de coaching"
                />
                Nous avons toutes et tous une histoire. Elle s’est construite au
                travers d'évènements de nature différente. Une vie qui a pu
                parfois être si douce et simple mais aussi rythmée par des
                moments difficiles et peut-être des évènements traumatiques.
                Mais tout ce qui a été écrit a forgé la personne que nous
                sommes.
              </p>
              <p>
                Après avoir essayé différentes méthodes d’accompagnement
                (psychologie, psychanalyse, psychiatrie et hypnose), celle qui
                est vraiment venue me toucher, me chercher et me révéler est
                l'accompagnement en développement personnel. Après 2 séances,
                j’ai rapidement compris et débloqué des croyances de mon être et
                me suis sentie beaucoup plus légère et naturelle. Une vie en
                alignement. Après une réorientation, un licenciement, une
                expatriation et des rencontres riches, j’ai réalisé, à mon tour,
                que l'unique sens de ma vie était d’aider les autres à devenir
                eux-même. Je me suis formée et aujourd’hui je me sens prête à
                t’aider et t’accompagner, tout au long de nos séances, et d’être
                la coach d’une vie ; celle qu’on peut appeler dès qu’un blocage
                se présente, celle qui saura utiliser les mots les plus justes,
                s’adapter à ta vision, ajuster avec toi ces croyances afin de
                t’aider à retourner sur le bon et beau chemin de ta vie.
              </p>
              <h3>Formations :</h3>
              <ul>
                <li>
                  Bachelor en management, spécialisation ressources-humaines -
                  Toulouse Business School
                </li>
                <li>
                  Maîtrise en Science de l’éducation - Institut Catholique de
                  Toulouse
                </li>
                <li>
                  2 années de bacc. en psychologie - Université Toulouse Jean
                  Jaurès
                </li>
                <li>
                  Certification masterclass Coaching Professionnel - Marine
                  Vincent
                </li>
                <li>
                  Certification de coach professionnel - International NLP
                </li>
                <li>Consultante en bilan de compétence - INTEAM Toulouse</li>
                <li>Life Coaching Certification Course - Kain Ramsay</li>
              </ul>
              <div className="at-featured">
                <div className="at-container">
                  <ul>
                    <li>
                      <img
                        width="300"
                        height="298"
                        src="/images/tbs-logo.png"
                        alt="Logo Toulouse Business School"
                        sizes="(max-width: 300px) 100vw, 300px"
                      />
                    </li>
                    <li>
                      <img
                        width="300"
                        height="298"
                        src="/images/ict-logo.png"
                        alt="Logo Institut catholique de Toulouse"
                        sizes="(max-width: 300px) 100vw, 300px"
                      />
                    </li>
                    <li>
                      <img
                        width="300"
                        height="298"
                        src="/images/universite-jean-jaures.png"
                        alt="Logo Université Jean Jaurès"
                        sizes="(max-width: 300px) 100vw, 300px"
                      />
                    </li>
                    <li>
                      <img
                        width="300"
                        height="298"
                        src="/images/marine-vincent-coaching.jpg"
                        alt="Logo Marine Vincent Coaching Professionnel"
                        sizes="(max-width: 300px) 100vw, 300px"
                      />
                    </li>
                    <li>
                      <img
                        width="300"
                        height="298"
                        src="/images/international-nlp-logo.png"
                        alt="Logo International NLP"
                        sizes="(max-width: 300px) 100vw, 300px"
                      />
                    </li>
                    <li>
                      <img
                        width="300"
                        height="298"
                        src="/images/kain-ramsay-logo.png"
                        alt="Logo Kain Ramsay"
                        sizes="(max-width: 300px) 100vw, 300px"
                      />
                    </li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <h3>Mentors inspirants :</h3>
              <div className="at-testimonals2">
                <ul className="at-testimonals2__list">
                  <li>
                    <div className="at-testimonial2 at-testimonial2--hasimage">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.lisebourbeau.com/"
                      >
                        <div
                          className="at-testimonial2__image"
                          style={styles.lise}
                        ></div>
                      </a>
                      <div className="at-testimonial2__contentwrap">
                        <div className="at-testimonial2__content">
                          <p>
                            <strong>Lise Bourbeau</strong> a mis sur pied un
                            atelier afin d’aider les gens à se connaître à
                            travers leur alimentation et leurs malaises et
                            maladies et a créé l’École Ecoute ton Corps afin de
                            former, motiver et aider des milliers de centaines
                            de personnes à devenir plus conscientes de leur
                            potentiel. Auteure à Succès, elle m’accompagne
                            depuis mon adolescence au travers de ses livres et
                            de ses vidéos.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="at-testimonial2 at-testimonial2--hasimage">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://davidlaroche.fr/"
                      >
                        <div
                          className="at-testimonial2__image"
                          style={styles.david}
                        ></div>
                      </a>
                      <div className="at-testimonial2__contentwrap">
                        <div className="at-testimonial2__content">
                          <p>
                            <strong>David Laroche</strong> est actuellement
                            considéré comme un des plus jeunes et grands
                            prodiges de la confiance en soi. Il coach
                            aujourd’hui des milliers de personnes à travers le
                            monde. Cela incluant des célébrités et des sportifs
                            de hauts niveaux. Son travail acharné et son esprit
                            sans limite, m’ont offert une perspective d’avenir
                            forte de sagesse et de réussite.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="at-testimonial2 at-testimonial2--hasimage">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.cerisebonneaud.com/"
                      >
                        <div
                          className="at-testimonial2__image"
                          style={styles.cerise}
                        ></div>
                      </a>
                      <div className="at-testimonial2__contentwrap">
                        <div className="at-testimonial2__content">
                          <p>
                            <strong>Cerise Bonneaud</strong> accompagne les
                            femmes à se créer une vie sans compromis, dans la
                            joie et la fluidité ! Auteur du livre “Ma vie sans
                            compromis”, Cerise m’inspire avec son sourire
                            rayonnant, sa bonne humeur et ses idées pétillantes.
                            Cette magnifique énergie fait d’elle une coach
                            impliquée et une mentor d’exception.
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </Layout>
        <SocialFooter />
        <Footer />
      </div>
    </>
  )
}
